.main_container {
  position: relative;
}

.main_container::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  background: no-repeat;
  width: 100vw;
  z-index: -20;
}

.navbar_container {
  display: flex;
  justify-content: space-between;
  height: 9vh;
  box-shadow: 0px 0px 0px 1px #111 inset;
  border-top: -1px solid #111;
  position: relative;
}

.navbar_container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: -2;
}

.navbar_container > img {
  width: auto;
}

.navbar_list_container > ul {
  display: flex;
  list-style: none;
  align-items: center;
  height: 100%;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
}

.navbar_list_container > ul > li {
  display: flex;
  padding-left: 3em;
  padding-right: 3em;
  border-left: 1px solid;
  height: 100%;
}

.navbar_list_container > ul > li > a {
  align-self: center;
  color: black;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 90%;
}

.container > img {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: -1.7rem;
  width: auto;
  height: 100%;
}

.content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.25rem;
  width: 85%;
}

.hero_button {
  width: fit-content;
  padding: 1em 2em;
  box-shadow: 4px 4px 0px #111;
  border: 4px solid black;
  border-radius: 0;
  background-color: #e35b5b;
  font-weight: 800;
}

.title_heading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title_heading_container > h1 {
  font-size: 6em;
  text-align: center;
  width: 100%;
  font-family: "mudclaw";
}

.title_heading_container > p {
  text-align: center;
  width: 70%;
}

.Watch_now {
  text-align: center;
}

@media only screen and (max-width: 950px) {
  .main_container::before {
    backdrop-filter: blur(20px);
  }
  .container > img {
    filter: blur(2px);
  }
  .container {
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .container > img {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .navbar_list_container > ul > li {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media only screen and (max-width: 500px) {
  .Watch_now {
    border-right: 1px solid black;
  }

  .navbar_container > img {
    display: none;
  }
  .navbar_container {
    display: flex;
    justify-content: space-around;
    height: 10vh;
    border-bottom: 1px solid #111;
    border-top: 1px solid #111;
    position: relative;
  }
}
@media only screen and (max-width: 400px) {
  .title_heading_container > h1 {
    font-size: 6em;
  }
  .title_heading_container > p {
    text-align: center;
    width: 98%;
  }
}
