@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.btn {
  width: fit-content;
  padding: 1em 2em;
  box-shadow: 4px 4px 0px #111;
  border: 4px solid black;
  border-radius: 0;
  font-weight: 800;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #e3e3e3;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e3e3e3;
  border-radius: 0.6rem;
  z-index: -99;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e486a1;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #e486a1;
}

@font-face {
  font-family: "mudclaw";
  src: local("Mudclaw"),
    url("../src/assets/Mudclaw/MUDCLAW.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "MabryPro-Regular";
  src: local("MabryPro-Regular"),
    url("../src/assets/MabryPro-Regular/MabryPro-Regular.ttf")
      format("truetype");
}
